import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [{
            path: "/",
            redirect: "/funcionario",
            component: () =>
                import ("@/view/layout/Layout"),
            children: [{

                    path: "/dashboard",
                    name: "dashboard",
                    component: () =>
                        import ("@/view/pages/Dashboard.vue")
                },





                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////funcionario////
                {
                    path: "/funcionario",
                    name: "funcionario",
                    component: () =>
                        import ("@/view/components/gerenciamento/listaFuncionario.vue")
                },


                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () =>
                        import ("@/view/components/gerenciamento/createFuncionario.vue")
                },

                // {
                //     path: "/controlePonto",
                //     name: "controlePonto",
                //     component: () =>
                //         import ("@/view/components/gerenciamento/listaControlePontos.vue")
                // },
                {
                    path: "/controlePonto/:id_funcionario?",
                    name: "controlePonto",
                    component: () =>
                        import ("@/view/components/gerenciamento/listaControlePontos.vue")
                },
                {
                    path: "/historico",
                    name: "historico",
                    component: () =>
                        import ("@/view/components/gerenciamento/listaHistoricos.vue")
                },



                // {
                //     path: "/createPedidoCompra",
                //     name: "createPedidoCompra",
                //     component: () =>
                //         import ("@/view/components/financeiro/orcamento_anual/createPedidoCompra.vue")

                //#endregion

                {
                    path: "/builder",
                    name: "builder",
                    component: () =>
                        import ("@/view/pages/Builder.vue")
                },








            ]
        },


        {
            path: "/",
            component: () =>
                import ("@/view/pages/auth/login_pages/Login-1"),
            children: [{
                    name: "login",
                    path: "/login",
                    component: () =>
                        import ("@/view/pages/auth/login_pages/Login-1")
                },

                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import ("@/view/pages/auth/login_pages/Login-1")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () =>
                import ("@/view/pages/error/Error-1.vue")
        },

    ]
});