import ApiService from "@/core/services/api.service";
// import PerfilService from "@/core/services/perfil.service";
import UsuarioService from "@/core/services/usuario.service";
import JwtService from "@/core/services/jwt.service";






const actions = {
    async PesquisarAluno(context, value) {
        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();


            await ApiService.post('usuario/buscar', value)
                .then(response => {
                    console.log(response.data)
                    context.commit('SET_LISTA_ALUNO', response.data.data)

                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }
    },


    // async setFilial(context, value) {
    //     // context.commit('SET_FILIAL', value)
    //     await FilialService.saveFilial(value)
    //     ApiService.setHeaderFilial();
    //     console.log(value)
    // },
    // setFilialGuardada(context) {
    //     ApiService.setHeaderFilial();
    //     let value = parseInt(FilialService.getFilial())
    //     ApiService.setHeaderFilial();
    //     let permissoes = JSON.parse(UsuarioService.getUsuario())

    //     if (permissoes[value] != null) {
    //         let array = permissoes[value].permissoes

    //         let objPermissoes = {}
    //         array.forEach((item) => {

    //             objPermissoes[item] = item
    //                 //        console.log(item);
    //                 // console.log(index);

    //         })

    //         context.commit('SET_LISTA_PERMISSOES', objPermissoes)
    //         console.log(objPermissoes)

    //     } else {
    //         console.log("filial nao permitida")
    //         context.commit('SET_LISTA_PERMISSOES', "")

    //     }
    // },
    async listar_permissoes_sede(context, value) {

        value = parseInt(value)
        let permissoes = JSON.parse(UsuarioService.getUsuario())

        if (permissoes[value] != null) {
            let array = permissoes[value].permissoes

            let objPermissoes = {}
            array.forEach((item) => {
                objPermissoes[item] = item
                    //        console.log(item);
                    // console.log(index);
            })

            context.commit('SET_LISTA_PERMISSOES', objPermissoes)

            console.log(objPermissoes)
        } else {
            console.log("filial nao permitida")
            context.commit('SET_LISTA_PERMISSOES', "")

        }



    },
    async listar_filiais_autorizadas(context) {

        let filiais = JSON.parse(UsuarioService.getUsuario())
        console.log(filiais)


        context.commit('SET_LISTA_FILIAIS_AUTORIZADAS', filiais)






    },

    // async listar_filiais_inicio(context) {
    //     // context.commit('SET_FILIAL', value)
    //     var filiais = FilialService.getUsuario()
    //     context.commit('SET_LISTA_FILIAIS_INICIO', JSON.parse(filiais))

    //     console.log(JSON.parse(filiais))
    // }

};
const getters = {

};
const mutations = {


    SET_LISTA_ALUNO(state, value) {
        state.lista_aluno = value
    },
    SET_LISTA_PERMISSOES(state, value) {
        state.lista_permissoes_filial = value
    },
    // SET_LISTA_FILIAIS_INICIO(state, value) {
    //     state.lista_filiais = value
    // }
    SET_LISTA_FILIAIS_AUTORIZADAS(state, value) {
        state.lista_filiais_autorizadas = value

    }

};
const state = {

    lista_aluno: {},
    lista_permissoes_filial: {},
    lista_filiais_autorizadas: []
        // lista_filiais: []
};

export default {
    state,
    actions,
    mutations,
    getters
};