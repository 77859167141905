import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
// import configFuncionario from "./vuex/modulos/configFuncionarios";
// import permissoes from "./vuex/modulos/permissoes"
// import cursos from "./vuex/modulos/cursos"
import configEmpresa from "./vuex/modulos/configEmpresa"
// import gradeCurricular from "./vuex/modulos/gradeCurricular/gradeCurricular"
// import financeiro from "./vuex/modulos/financeiro/financeiro"
// import academico from "./vuex/modulos/academico"
// import finanOrcamentoAnual from "./vuex/modulos/financeiro/finan0rcamentoAnual"
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        // configFuncionario,
        // permissoes,
        // cursos,
        configEmpresa,
        // gradeCurricular,
        // academico,
        // financeiro,
        // finanOrcamentoAnual

    }
});