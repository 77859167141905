import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UsuarioService from "@/core/services/usuario.service";
export const SET_LISTA_FUNCIONARIOS = "setListFuncionarios"

export const SET_LISTA_REGISTROS = "setListRegistros"
export const SET_LISTA_REGISTRO_FUNCIONARIO = "setListRegistroFuncionario"
export const SET_DADOS_USU = "setDadosLogado"


const actions = {



    async create_funcionario(context, value) {
        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.post('funcionario/criar', value)
                .then(response => {
                    console.log(response)
                    let a = {
                        tipo: 'success',
                        message: response.data
                    }
                    context.commit('SET_MESSAGE_ALERT', a)

                })
                .catch((error) => {
                    console.log(error)
                    let a = {
                        tipo: 'error',
                        message: 'ouve algum problema consulte os dados ou a internet'
                    }
                    context.commit('SET_MESSAGE_ALERT', a)
                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }
    },
    async update_funcionario(context, value) {
        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.put('funcionario/editar/' + value.id, value)
                .then(response => {
                    console.log(response)
                    let a = {
                        tipo: 'success',
                        message: response.data
                    }
                    context.commit('SET_MESSAGE_ALERT', a)

                })
                .catch((error) => {
                    console.log(error)
                    let a = {
                        tipo: 'error',
                        message: 'ouve algum problema consulte os dados ou a internet'
                    }
                    context.commit('SET_MESSAGE_ALERT', a)
                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }
    },


    ///listagens 

    async listar_funcionarios(context, value) {
        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.get('funcionario/lista')
                .then(response => {
                    console.log(response.data)
                    context.commit(SET_LISTA_FUNCIONARIOS, response.data)

                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },
    async listar_registro_relogio(context, value) {
        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.get('funcionario/registros')
                .then(response => {
                    console.log(response.data)
                    context.commit(SET_LISTA_REGISTROS, response.data)

                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },

    async listar_registro_relogio_funcionario(context, value) {

        console.log(value) //
        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.get('funcionario/registro/' + value.id)
                .then(response => {
                    console.log(response.data)
                    // context.commit(SET_LISTA_REGISTRO_FUNCIONARIO, response.data)
                    context.commit(SET_LISTA_REGISTROS, response.data)


                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },
    async filtro_registros(context, value) {
        console.log(value) //

        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.post('funcionario/registros/filtro', value)
                .then(response => {
                    console.log(response.data)
                    context.commit(SET_LISTA_REGISTROS, response.data)

                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },
    async filtro_registros_excel(context,value) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
      await  ApiService.getArquivo(`/funcionario/registros/filtro/excel`,value);

        // context.commit(SET_EXCEL_FINAN_RELATORIO_, response.data)

    }

    },
 
        
    
    async dados_logado(context, value) {
        console.log(value) //

        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.post('funcionario/dados_usu')
                .then(response => {
                    console.log(response.data)
                    context.commit(SET_DADOS_USU, response.data)
                    UsuarioService.saveUsuario(response.data)
                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },
    async autorizar_send_biometria(context, value) {
        console.log(value) //

        if (JwtService.getToken()) {
            ApiService.setHeader();

            await ApiService.put('funcionario/autorizar/' + value.id)
                .then(response => {
                    console.log(response.data)
                        // context.commit(SET_DADOS_USU, response.data)
                        // UsuarioService.saveUsuario(response.data)
                    let a = {
                        tipo: 'success',
                        message: response.data
                    }
                    context.commit('SET_MESSAGE_ALERT', a)
                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    //   context.commit('PRELOADER_MUDAR', false)
                })
        }

    },





    MudarPreloader(context, value) {
        console.log('mudado pre')
        console.log(value)
        context.commit('SET_PRELOADER', value)
    },

    atualizar(context, value) {
        console.log(value)

        context.commit('SET_CAMPOS', value)
    },
    guardarDados(context, value) {
        console.log(value)
        context.commit('SET_DADOS_GUARDADOS', value)
    },
    controle_ponto(context, value) {
        context.commit('SET_ID_FUN', value.ID)
    },
    limpar(context) {
        context.commit(SET_LISTA_REGISTROS, 0)
    }


};
const getters = {

};
const mutations = {
    [SET_LISTA_FUNCIONARIOS](state, value) {
        state.lista_funcionarios = value
    },
    [SET_LISTA_REGISTROS](state, value) {
        state.lista_registros = value
    },
    [SET_LISTA_REGISTRO_FUNCIONARIO](state, value) {
        state.lista_registro_funcionario = value
    },
    [SET_DADOS_USU](state, value) {
        state.dados_usu = value
    },

    SET_ID_FUN(state, value) {
        state.id_fun = value
    },
    SET_PRELOADER(state, value) {
        state.isLoading = value
    },
    SET_CAMPOS(state, value) {
        state.lista_campos = value
    },
    SET_DADOS_GUARDADOS(state, value) {
        state.lista_dados_guardados = value
    },
    SET_MESSAGE_ALERT(state, value) {
        state.mensagem_alert = {
            tipo: value.tipo,
            message: value.message

        }
    },

};
const state = {
    dados_usu: [],
    lista_empresas: [],
    lista_registros: [],
    lista_registro_funcionario: [],
    id_fun: '',
    isLoading: false,
    lista_campos: '',
    mensagem_alert: '',
    lista_funcionarios: [],
    lista_dados_guardados: []


};

export default {
    state,
    actions,
    mutations,
    getters
};